import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import posthog from "posthog-js";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IS_MARQUEE_SLIDER_ACTIVE } from "../components/v2/Layout/Header";
import Benefits from "../components/v2/pages/HomePage/Benefits";
import Celebrity from "../components/v2/pages/HomePage/Celebrity";
import ChoosePlan from "../components/v2/pages/HomePage/ChoosePlan";
import Compare from "../components/v2/pages/HomePage/Compare";
import FeaturedSection from "../components/v2/pages/HomePage/FeaturedSection";
import GlobalEvents from "../components/v2/pages/HomePage/GlobalEvents";
import Hero from "../components/v2/pages/HomePage/Hero";
import OfficeTour from "../components/v2/pages/HomePage/OfficeTour";
import PricingTableNew from "../components/v2/pages/HomePage/PriceTableNew";
import TradeSmartWin from "../components/v2/pages/HomePage/TradeSmartWin";
import Testimonialv2 from "../components/v2/pages/HomePage/testimonialv2";
import { setStartChallengeCTA } from "../redux/slices/abTestingSlice";
const HomePage = ({ initialFeatureFlags }) => {
    const dispatch = useDispatch();
    dispatch(setStartChallengeCTA(initialFeatureFlags));
    const grayBg = useRef(null);
    const haitiBg = useRef(null);

    useGSAP(
        () => {
            const tab = gsap.matchMedia();
            tab.add("(min-width: 1280px)", () => {
                gsap.to(".home-bg-one", {
                    paddingLeft: 0,
                    paddingRight: 0,
                    scrollTrigger: {
                        trigger: ".home-bg-one",
                        start: "top 70%",
                        end: "top 30%",
                        scrub: 2,
                        disable: true
                    }
                });
                gsap.to(".home-bg-one", {
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    immediateRender: false,
                    scrollTrigger: {
                        trigger: ".home-bg-one",
                        start: "bottom 70%",
                        end: "bottom 30%",
                        scrub: 2
                    }
                });
            });
        },
        { scope: grayBg }
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setIsModalOpen(true);
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={`${IS_MARQUEE_SLIDER_ACTIVE ? "mt-20" : ""}`}>
            <Hero />
            {/* 
            <OfferModal isOpen={isModalOpen} onClose={closeModal} />
            <OfferModalMobile isOpen={isModalOpen} onClose={closeModal} /> */}

            <section ref={grayBg} className="relative">
                <div className="home-bg-one bg-black  xl:px-[150px]">
                    <div className="flex flex-col gap-y-[60px] bg-[#141414] py-[50px] lg:rounded-[50px] xl:gap-y-[100px] xl:py-[160px]">
                        <Benefits />
                        <FeaturedSection />
                    </div>
                </div>
            </section>
            {/* <PricingTable /> */}
            <PricingTableNew />
            <ChoosePlan />

            <section ref={haitiBg} className="relative">
                <div className="home-bg-two  bg-black">
                    {/* bg-haiti xl:rounded-[50px] */}
                    <div className={`bg-haiti`}>
                        <Compare />
                        <Celebrity />
                    </div>
                </div>
            </section>
            <Testimonialv2 />
            <section className="relative w-full py-[40px]  lg:py-[100px]">
                <div className="container-v2">
                    <GlobalEvents />
                    <OfficeTour />
                    <TradeSmartWin />
                </div>
            </section>
        </div>
    );
};

export default HomePage;

HomePage.layout = "NewLayout";

export async function getServerSideProps(context) {
    // Initialize PostHog server-side and fetch feature flags
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST
    });

    const initialFeatureFlags = posthog.getFeatureFlag("start-challenge-cta");

    return {
        props: {
            initialFeatureFlags: initialFeatureFlags
                ? initialFeatureFlags
                : "control"
        }
    };
}
